const links = [
  {
    label: "Início",
    url: "/#topo",
  },
  {
    label: "INFORMAÇÕES E DICAS",
    url: "/#informacoes-e-dicas",
  },
  {
    label: "SOBRE NÓS",
    url: "/#sobre-nos",
  },
  {
    label: "ENSAIO",
    url: "/ensaio#ensaio",
  },
  {
    label: "LISTA DE PRESENTES",
    url: "https://casamento.pontofrio.com.br/guilhermeeluana",
  },
]
export default links
